.App {
  position: relative;
}

.App::before {
  bottom: 0;
  background-position: bottom center;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 90%
  );
}

.Header {
  min-height: calc(80 * var(--vh-static));
  padding: 1.6rem;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 10%;
}

.Payoff {
  font-size: calc(3 * var(--vh-static));
}

.Header::before {
  top: 0;
  background-position: top center;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.App::before,
.Header::before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: calc(50 * var(--vh-static));
  left: 0;
  background-image: url("https://plus.unsplash.com/premium_photo-1687428554753-2ba702e2684f?q=75&w=768&auto=compress,format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(2px) opacity(0.5);
}

.Chapters {
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding: 0 1.6rem;
}

.Footer {
  padding: 0 1.6rem 1.6rem;
  text-align: center;
}
