:root {
  --fw-thin: 100;
  --fw-light: 300;
  --fw-bold: 700;

  --font-family-base: "Lato", "Helvetica Neue", sans-serif;
}

*,
&::before,
&::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-tap-highlight-color: transparent;
  font-size: 62.5%;
}

body {
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070303;
  color: #d8d8d8;
  font-weight: var(--fw-light);
  line-height: 1.7;
  font-size: calc(2.4 * var(--vh-static));

  @media screen and (min-width: 768px) {
    font-size: calc(2 * var(--vh-static));
    line-height: 1.4;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3 {
  font-family: "Lato", serif;
  font-weight: var(--fw-bold);
  margin-bottom: 0.3em;
  line-height: 1.1;
  text-transform: uppercase;
  color: #eee;
  opacity: 0.9;
}

h1 {
  font-size: calc(6.3 * var(--vh-static));

  @media screen and (min-width: 768px) {
    font-size: calc(9.6 * var(--vh-static));
  }
}

h2 {
  font-size: calc(4.2 * var(--vh-static));

  @media screen and (min-width: 768px) {
    font-size: calc(6.3 * var(--vh-static));
  }
}

p {
  margin-bottom: 1em;
}

a {
  color: deepskyblue;
  font-weight: var(--fw-bold);
  transition: opacity 0.2s ease-out;

  &:hover,
  &:focus {
    opacity: 0.65;
  }
}